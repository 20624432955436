
const Houses = {
    "Adult Great Fairy's Fountain": "Adult Great Fairy's Fountain",
    "Adult Shooting Gallery": "Adult Shooting Gallery",
    "Back Alley House": "Back Alley House",
    "Bazaar": "Bazaar",
    "Bombchu Bowling": "Bombchu Bowling",
    "Bombchu Shop": "Bombchu Shop",
    "Carpenter Tent": "Carpenter Tent",
    "Bottom House": "Bottom House",
    "Child Great Fairy's Fountain": "Child Great Fairy's Fountain",
    "Child Shooting Gallery": "Child Shooting Gallery",
    "Dampe's Hut": "Dampe's Hut",
    "Fairy Fountain": "Fairy Fountain",
    "Fishing": "Fishing",
    "Granny's Potion Shop": "Granny's Potion Shop",
    "Great Fairy's Fountain": "Great Fairy's Fountain",
    "Guard Hut/Poe Collector": "Guard Hut/Poe Collector",
    "House": "House",
    "House with Talon": "House with Talon",
    "Impa's House Cow": "Impa's House Cow",
    "Impa's House Front": "Impa's House Front",
    "Know-It-All House": "Know-It-All House",
    "Lakeside Laboratory": "Lakeside Laboratory",
    "Link's House": "Link's House",
    "Lon Lon Tower": "Lon Lon Tower",
    "Mask Shop": "Mask Shop",
    "Mido's House": "Mido's House",
    "Potion Shop Back": "Potion Shop Back",
    "Potion Shop Front": "Potion Shop Front",
    "Potion Shop": "Potion Shop",
    "Saria's House": "Saria's House",
    "Shop": "Shop",
    "Skulltula House": "Skulltula House",
    "Stable": "Stable",
    "Talon's House": "Talon's House",
    "Temple of Time": "Temple of Time",
    "Treasure Box Shop": "Treasure Box Shop",
    "Twins House": "Twins House",
    "Windmill": "Windmill"
};

export default Houses;
