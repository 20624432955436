
const Grottos = {
    "1 Scrub": "1 Scrub",
    "2 Scrubs": "2 Scrubs",
    "3 Scrubs": "3 Scrubs",
    "Bomb Grotto": "Bomb Grotto",
    "Boulder Grotto": "Boulder Grotto",
    "Boulder Across River": "Boulder Across River",
    "Boulder in Trees": "Boulder in Trees",
    "Boulder on Ledge": "Boulder on Ledge",
    "Boulder Near Castle Town": "Boulder Near Castle Town",
    "Boulder Near Death Mountain Trail": "Boulder Near Death Mountain Trail",
    "Boulder Near Gerudo Valley": "Boulder Near Gerudo Valley",
    "Boulder Near Goron City": "Boulder Near Goron City",
    "Boulder Near Sacred Forest Meadow": "Boulder Near Sacred Forest Meadow",
    "Composer Grave": "Composer Grave",
    "Cow and Skulltula Grotto": "Cow and Skulltula Grotto",
    "Cow Grotto": "Cow Grotto",
    "Dampe's Grave": "Dampe's Grave",
    "Diving Grotto": "Diving Grotto",
    "Diving Rupee Grotto": "Diving Rupee Grotto",
    "Fairy Fountain": "Fairy Fountain",
    "Forest Stage": "Forest Stage",
    "Grave": "Grave",
    "Grotto": "Grotto",
    "Near Lake Inside Fence Grotto": "Near Lake Inside Fence Grotto",
    "Open Grotto": "Open Grotto",
    "Redead Grave": "Redead Grave",
    "Redead Grotto": "Redead Grotto",
    "Shield Grave": "Shield Grave",
    "Skulltula Grotto": "Skulltula Grotto",
    "Song of Storms Grotto": "Song of Storms Grotto",
    "Tektite Grotto": "Tektite Grotto",
    "Tree Near Kakariko Grotto": "Tree Near Kakariko Grotto",
    "Wolfos Grotto": "Wolfos Grotto"
};

export default Grottos;
