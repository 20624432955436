
const Dungeons = {
    "Deku Tree": "Deku Tree",
    "Dodongo's Cavern": "Dodongo's Cavern",
    "Jabu Jabu's Belly": "Jabu Jabu's Belly",
    "Forest Temple": "Forest Temple",
    "Fire Temple": "Fire Temple",
    "Ice Cavern": "Ice Cavern",
    "Water Temple": "Water Temple",
    "Bottom of the Well": "Bottom of the Well",
    "Shadow Temple": "Shadow Temple",
    "Gerudo Training Grounds": "Gerudo Training Grounds",
    "Spirit Temple": "Spirit Temple"
};

export default Dungeons;
