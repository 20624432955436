
const EntranceTypes = {
    "Dungeon": "Dungeon",
    "Grotto": "Grotto",
    "House": "House",
    "Overworld": "Overworld",
    "Kaepora Gaebora": "Kaepora Gaebora",
    "Song": "Song"
};

export default EntranceTypes;
